import axios from 'axios';
import { IResponse } from '~/services/api/types';
import { ITableCustomization } from './types';
import { TableCustomizationType } from '../enums';

const apiUrl = 'table-customizations';

export const getByTypeId = (typeId: TableCustomizationType): IResponse<ITableCustomization[]> =>
  axios({
    method: 'get',
    url: `${apiUrl}/by-type-id/${typeId}`,
  });

export const updateByTypeId = (
  typeId: TableCustomizationType,
  data: ITableCustomization[],
): IResponse<ITableCustomization[]> =>
  axios({
    method: 'put',
    url: `${apiUrl}/by-type-id/${typeId}`,
    data,
  });

export default {};
