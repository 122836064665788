import axios from 'axios';
import { IResponse } from '~/services/api/types';
import {
  ITemplate,
  ITemplateCreate,
  ITemplateSelector,
  ITemplateUpdate,
  ITemplatesListFilters,
  ITemplatesSelectors,
} from './types';
import { TemplateType } from '../enums';

const apiUrl = 'templates';

export const getList = (params: ITemplatesListFilters): IResponse<ITemplate[]> =>
  axios({
    method: 'get',
    url: apiUrl,
    params,
  });

export const get = (id: number): IResponse<ITemplate> =>
  axios({
    method: 'get',
    url: `${apiUrl}/${id}`,
  });

export const getByTypeId = (typeId: TemplateType): IResponse<ITemplateSelector[]> =>
  axios({
    method: 'get',
    url: `${apiUrl}/by-type-id/${typeId}`,
  });

export const getSelectors = (): IResponse<ITemplatesSelectors> =>
  axios({
    method: 'get',
    url: `${apiUrl}/selectors`,
  });

export const create = (data: ITemplateCreate): IResponse<ITemplate> =>
  axios({
    method: 'post',
    url: apiUrl,
    data,
  });

export const update = (data: ITemplateUpdate): IResponse<ITemplate> =>
  axios({
    method: 'put',
    url: apiUrl,
    data,
  });

export const remove = (id: number): IResponse<void> =>
  axios({
    method: 'delete',
    url: `${apiUrl}/${id}`,
  });

export default {};
