import { useState, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Grid } from '@material-ui/core';

import api from '~/services/api';
import { useStoreActions } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';
import validate from './form/validate';
import Button from '~/ui/components/common/Button';
import Select from '~/ui/components/inputs/Select';
import Loader from '~/ui/components/common/Loader';

import { CODE_TYPE_OPTIONS } from './constants';
import { ICarePlanIcd10Code } from '~/services/api/carePlanIcd10Codes/types';
import { IOption } from '~/ui/components/inputs/Select/types';
import { IFormValues } from './form/types';
import styles from './ApplyCode.module.scss';
import { IIdName } from '~/services/api/types';

interface IProps {
  mode?: 'create' | 'edit';
  patientId: number;
  currentEditCarePlanIcd10Code?: ICarePlanIcd10Code;
  initialValues: IFormValues;
  onClose: () => void;
  onCarePlanChange: () => void;
}

const ApplyCode = ({
  mode = 'create',
  patientId,
  currentEditCarePlanIcd10Code,
  initialValues,
  onClose,
  onCarePlanChange,
}: IProps) => {
  const [icd10CodeSelectors, setIcd10CodeSelectors] = useState<IIdName[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  const { onCreateCarePlanIcd10Code, onUpdateCarePlanIcd10Code } = useStoreActions(
    actions => actions.carePlanIcd10Codes,
  );

  const { showError } = useStoreActions(actions => actions.snackbar);

  const icd10CodeOptions: IOption[] = useMemo(
    () => [
      ...(currentEditCarePlanIcd10Code
        ? [
            {
              value: currentEditCarePlanIcd10Code.icd10CodeId,
              label: `${currentEditCarePlanIcd10Code.icd10CodeName} - ${currentEditCarePlanIcd10Code.icd10CodeDescription}`,
            },
          ]
        : []),
      ...icd10CodeSelectors.map(item => ({
        value: item.id,
        label: item.name,
      })),
    ],
    [icd10CodeSelectors, currentEditCarePlanIcd10Code],
  );

  const getAvailableIcd10Codes = async () => {
    try {
      setIsLoading(true);
      const carePlanSelectors = await api.patients
        .getCarePlanSelectorsById(patientId)
        .then(res => res.data);
      setIcd10CodeSelectors(carePlanSelectors.icd10Codes);
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreateIcd10Code = async (values: IFormValues) => {
    try {
      setIsSaving(true);
      await onCreateCarePlanIcd10Code({ patientId, data: values as ICarePlanIcd10Code });
      onCarePlanChange();
      onClose();
    } catch (e) {
      showError(extractErrorMessage(e));
      setIsSaving(false);
    }
  };

  const handleUpdateIcd10Code = async (values: IFormValues) => {
    const payload = {
      ...values,
      id: currentEditCarePlanIcd10Code.id,
    } as ICarePlanIcd10Code;

    try {
      setIsSaving(true);
      await onUpdateCarePlanIcd10Code(payload);
      onCarePlanChange();
      onClose();
    } catch (e) {
      showError(extractErrorMessage(e));
      setIsSaving(false);
    }
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: validate,
    defaultValues: initialValues,
  });

  const onSubmit = (values: IFormValues) => {
    if (mode === 'create') {
      handleCreateIcd10Code(values);
    } else {
      handleUpdateIcd10Code(values);
    }
  };

  const onMount = () => {
    getAvailableIcd10Codes();
  };

  useEffect(() => {
    onMount();
  }, []);

  if (isLoading) return <Loader />;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Select
            name="typeId"
            label="Type *"
            options={CODE_TYPE_OPTIONS}
            control={control}
            errors={errors}
          />
        </Grid>
        <Grid item xs={9}>
          <Select
            name="icd10CodeId"
            label="Code *"
            options={icd10CodeOptions}
            control={control}
            errors={errors}
          />
        </Grid>
      </Grid>
      <div className={styles.btnWrapper}>
        <Button variant="outlined" color="default" disabled={isSaving} onClick={onClose}>
          Cancel
        </Button>
        <div className={styles.widthSpacer} />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          isLoading={isSaving}
          disabled={isSaving}
        >
          Save
        </Button>
      </div>
    </form>
  );
};

export default ApplyCode;
