import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkUpdateCarePlanGoal } from '../types';

const onUpdateCarePlanGoal: IThunkUpdateCarePlanGoal = thunk(
  // @ts-ignore
  async ({ updateCarePlanGoal }, payload) => {
    const { data } = await api.carePlanGoals.update(payload);
    updateCarePlanGoal(data);
  },
);

export default onUpdateCarePlanGoal;
