import { ReactElement, useState } from 'react';
import { Grid } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import { ITemplateList } from '~/services/api/templates/types';
import TemplateModal from '../../TemplateModal';
import ConfirmModal from '~/ui/components/common/ConfirmModal';
import { useStoreActions } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';

interface IProps {
  item: ITemplateList;
}

const Item = ({ item }: IProps): ReactElement => {
  const [isTemplateModalOpen, setIsTemplateModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);

  const { onDeleteTemplate } = useStoreActions(actions => actions.templates);
  const { showError } = useStoreActions(actions => actions.snackbar);

  const handleRemove = async () => {
    try {
      setIsRemoving(true);

      await onDeleteTemplate(item.id);

      setIsConfirmModalOpen(false);
    } catch (e) {
      showError(extractErrorMessage(e));
      setIsRemoving(false);
    }
  };

  return (
    <>
      <TableRow>
        <TableCell>{item.name}</TableCell>
        <TableCell>{item.typeName}</TableCell>
        <TableCell>{item.createdByUserFullName}</TableCell>
        <TableCell>
          <Grid container>
            <Grid item>
              <IconButton title="Edit" color="primary" onClick={() => setIsTemplateModalOpen(true)}>
                <CreateIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                title="Remove"
                color="secondary"
                onClick={() => setIsConfirmModalOpen(true)}
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>
      {isTemplateModalOpen && (
        <TemplateModal templateId={item.id} onClose={() => setIsTemplateModalOpen(false)} />
      )}
      {isConfirmModalOpen && (
        <ConfirmModal
          title="Remove"
          description="Are you sure you want to remove current template?"
          isLoading={isRemoving}
          onClose={() => setIsConfirmModalOpen(false)}
          onConfirm={handleRemove}
        />
      )}
    </>
  );
};

export default Item;
