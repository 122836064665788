import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkUpdateIsSmoker } from '../types';

const onUpdateIsSmoker: IThunkUpdateIsSmoker = thunk(
  // @ts-ignore
  async ({ setIsSmoker }, payload) => {
    const { patientId, data } = payload;
    const { isSmoker } = await api.patients.updateIsSmoker(patientId, data).then(res => res.data);

    setIsSmoker(isSmoker);
  },
);

export default onUpdateIsSmoker;
