import { ICarePlanMedication } from '~/services/api/carePlanMedications/types';
import { IFormValues } from '../../popups/MedicationModal/form/types';

const getEditValues = (medication: ICarePlanMedication): IFormValues => ({
  name: medication.name,
  reason: medication.reason,
  startDate: medication.startDate,
  endDate: medication.endDate,
});

export default getEditValues;
