import { ReactElement, ReactNode } from 'react';
import { CircularProgress, Button, ButtonProps } from '@material-ui/core';
import classNames from 'classnames';

import styles from './Button.module.scss';

interface ICommon {
  children?: ReactNode;
  isLoading?: boolean;
  textTransformNone?: boolean;
}

type IProps = ICommon & ButtonProps;

// This component extends basic MUI Button component
// It supports: isLoading (loader spinner)
const ButtonComponent = ({
  children,
  isLoading = false,
  textTransformNone = false,
  className,
  ...props
}: IProps): ReactElement => (
  <Button
    {...props}
    className={classNames(className, { [styles.textTransformNone]: textTransformNone })}
  >
    <>
      {children}
      {isLoading && (
        <>
          <div className={styles.spacer} />
          <CircularProgress size={20} />
        </>
      )}
    </>
  </Button>
);

export default ButtonComponent;
