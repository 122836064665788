import { ReactElement, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';
import SearchIcon from '@mui/icons-material/Search';
import RotateRightIcon from '@mui/icons-material/RotateRight';

import { getInitialFilters, validate } from './form';
import Select from '~/ui/components/inputs/Select';
import Button from '~/ui/components/common/Button';

import { ITemplatesListFilters, ITemplatesSelectors } from '~/services/api/templates/types';
import { allOption } from '~/ui/constants/allOptions';
import Input from '~/ui/components/inputs/Input';
import templateTypeOptions from '~/ui/constants/templateTypeOptions';

import styles from './Header.module.scss';

interface IProps {
  selectors: ITemplatesSelectors;
  onSubmit: (filters: ITemplatesListFilters) => void;
}

const Header = ({ selectors, onSubmit }: IProps): ReactElement => {
  const initialFilters = getInitialFilters();

  const formMethods = useForm<ITemplatesListFilters>({
    resolver: validate,
    defaultValues: initialFilters,
  });

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = formMethods;

  const allTemplateTypeOptions = [allOption, ...templateTypeOptions];

  const createdByUserOptions = useMemo(
    () => [
      allOption,
      ...selectors.createdByUsers.map(item => ({
        value: item.id,
        label: item.name,
      })),
    ],
    [selectors.createdByUsers],
  );

  const onSubmitForm = (values: ITemplatesListFilters) => {
    onSubmit(values);
  };

  return (
    <FormProvider {...formMethods}>
      <form className={styles.container} onSubmit={handleSubmit(onSubmitForm)}>
        <Grid container spacing={2}>
          <Grid item xs>
            <Input name="name" register={register} errors={errors} label="Name" />
          </Grid>
          <Grid item xs>
            <Select
              name="typeId"
              control={control}
              errors={errors}
              options={allTemplateTypeOptions}
              label="Type"
            />
          </Grid>
          <Grid item xs>
            <Select
              name="createdByUserId"
              control={control}
              errors={errors}
              options={createdByUserOptions}
              label="Author"
            />
          </Grid>
          <Grid item xs={4} />
          <Grid item>
            <Button variant="outlined" type="submit" color="primary" startIcon={<SearchIcon />}>
              Search
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              type="button"
              startIcon={<RotateRightIcon />}
              onClick={() => {
                reset(initialFilters);
                onSubmit(initialFilters);
              }}
            >
              Reset
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default Header;
