import { useState, useRef, ReactElement } from 'react';

import Tooltip from '@material-ui/core/Tooltip';
import Button from '~/ui/components/common/Button';
import MainForm from './components/MainForm';
import NotificationRules from './components/NotificationRules';
import AddNotificationRuleForm from './components/AddNotificationRuleForm';

import { typeOptions } from '~/ui/constants/notificationRule';
import { IProgram } from '~/services/api/programs/types';
import styles from './ProgramForm.module.scss';
import { INotificationRule } from '~/services/api/notificationRules/types';

interface IProps {
  initialValues: IProgram;
  isProcessing: boolean;
  onSubmit: (data: IProgram) => void;
}

const ProgramForm = ({ initialValues, isProcessing, onSubmit }: IProps): ReactElement => {
  const { rules, ...formInitialValues } = initialValues;

  const formRef = useRef(null);

  const [notificationRules, setNotificationRules] = useState<INotificationRule[]>(rules || []);
  const [showAddNotificationRule, setShowNotificationRule] = useState(false);

  const addNotificationRule = (rule: INotificationRule) => {
    setNotificationRules(prev => [...prev, rule]);
    setShowNotificationRule(false);
  };

  const removeNotificationRule = (id: number) => {
    setNotificationRules(prev => prev.filter(item => item.id !== id));
  };

  const handleSubmit = () => {
    // call submit form action using ref dispatchEvent
    formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
  };

  const onSubmitMainForm = (data: IProgram) => {
    const formattedRules = notificationRules.map(item => {
      const clonedItem = { ...item };

      if (!rules.some(x => x.id === item.id)) {
        clonedItem.id = 0;
      }

      return clonedItem;
    });

    onSubmit({
      ...data,
      rules: formattedRules,
    });
  };

  // Multiple by two becase we have 2 cases per typeOption
  const disabledButton = notificationRules.length === typeOptions.length * 2;
  return (
    <>
      <MainForm ref={formRef} initialValues={formInitialValues} onSubmit={onSubmitMainForm} />
      <NotificationRules rules={notificationRules} onRemove={removeNotificationRule} />
      {showAddNotificationRule ? (
        <AddNotificationRuleForm
          rules={notificationRules}
          onSubmit={addNotificationRule}
          onCancel={() => setShowNotificationRule(false)}
        />
      ) : (
        <Tooltip title={disabledButton ? 'The maximum number of notification rules used' : ''}>
          <span>
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={() => setShowNotificationRule(true)}
              disabled={disabledButton}
            >
              + Add Rule
            </Button>
          </span>
        </Tooltip>
      )}
      <div className={styles.saveBtnWrapper}>
        <Button
          size="large"
          variant="contained"
          color="primary"
          isLoading={isProcessing}
          disabled={isProcessing}
          onClick={handleSubmit}
        >
          Save
        </Button>
      </div>
    </>
  );
};

export default ProgramForm;
