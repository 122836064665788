import { IPatientsListFilters } from '~/services/api/patients/types';
import { PatientStatus } from '~/services/api/enums';
import { getCurrentMonthFirstDayDate, setEndOfDay } from '~/utils/date';

export const getDefaultValues = (): IPatientsListFilters => ({
  startDate: getCurrentMonthFirstDayDate(),
  endDate: setEndOfDay(new Date()),
  programId: '',
  fullName: '',
  healthCareManagerIds: [],
  status: PatientStatus.Active,
  isHomeHealthCare: '',
});

export const getInitialValues = (): IPatientsListFilters => {
  const patientsFiltersJson = localStorage.getItem('patientsFilters');
  const patientsFilters = patientsFiltersJson
    ? (JSON.parse(patientsFiltersJson) as IPatientsListFilters)
    : {};

  return {
    startDate: getCurrentMonthFirstDayDate(),
    endDate: setEndOfDay(new Date()),
    programId: '',
    fullName: '',
    healthCareManagerIds: [],
    status: PatientStatus.Active,
    isHomeHealthCare: '',
    ...patientsFilters,
  };
};

export default {};
