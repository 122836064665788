import { Grid, Tabs } from '@material-ui/core';
import MuiTab from '@material-ui/core/Tab';
import { ReactElement, useState } from 'react';
import { Link, Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { IRoutableTab } from './types';
import styles from './RoutableTabs.module.scss';
import Button from '../Button';

interface IProps {
  tabs: IRoutableTab[];
}

const RoutableTabs = ({ tabs }: IProps): ReactElement => {
  const routeMatch = useRouteMatch(tabs.map(x => x.path));
  const currentTab = routeMatch?.path ?? tabs[0].path;

  const [selectedTab, setSelectedTab] = useState(
    tabs.find(x => x.path === routeMatch?.path) ?? tabs[0],
  );

  return (
    <>
      <Grid container>
        <Grid item>
          <Tabs
            value={currentTab}
            TabIndicatorProps={{ children: <span /> }}
            onChange={(_, path) => setSelectedTab(tabs.find(x => x.path === path))}
          >
            {tabs.map(tab => (
              <MuiTab
                key={tab.path}
                label={tab.label}
                value={tab.path}
                to={tab.route}
                component={Link}
                disableRipple
              />
            ))}
          </Tabs>
        </Grid>
        <Grid item xs />
        {selectedTab.onButtonClick && (
          <Grid item>
            <Button variant="contained" color="primary" onClick={selectedTab.onButtonClick}>
              {selectedTab.buttonLabel}
            </Button>
          </Grid>
        )}
      </Grid>
      <div className={styles.container}>
        <Switch>
          {tabs.map(tab => (
            <Route key={tab.path} exact path={tab.path}>
              {tab.component}
            </Route>
          ))}
          <Redirect to={tabs[0].route} />
        </Switch>
      </div>
    </>
  );
};

export default RoutableTabs;
