import setCarePlanGoals from './setCarePlanGoals';
import addCarePlanGoal from './addCarePlanGoal';
import updateCarePlanGoal from './updateCarePlanGoal';
import removeCarePlanGoal from './removeCarePlanGoal';

export default {
  setCarePlanGoals,
  addCarePlanGoal,
  updateCarePlanGoal,
  removeCarePlanGoal,
};
