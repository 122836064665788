import onGetCarePlanGoals from './onGetCarePlanGoals';
import onCreateCarePlanGoal from './onCreateCarePlanGoal';
import onUpdateCarePlanGoal from './onUpdateCarePlanGoal';
import onRemoveCarePlanGoal from './onRemoveCarePlanGoal';

export default {
  onGetCarePlanGoals,
  onCreateCarePlanGoal,
  onUpdateCarePlanGoal,
  onRemoveCarePlanGoal,
};
