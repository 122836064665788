import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { getPaginationPayload } from '~/utils/pagination';
import { IThunkGetTemplates, ITemplatesStore } from '../types';

const onGetTemplates: IThunkGetTemplates = thunk(
  // @ts-ignore
  async ({ setTemplates, reset }, filters, { getState }) => {
    reset();

    const localState = getState() as ITemplatesStore;

    const paginationPayload = getPaginationPayload(localState.pagination);

    const { data } = await api.templates.getList({
      ...filters,
      ...paginationPayload,
    });

    setTemplates(data);
  },
);

export default onGetTemplates;
