import { ReactElement, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import { Grid } from '@material-ui/core';
import DashboardIcon from '@mui/icons-material/Dashboard';
import EditTableModal from '~/ui/components/reusable/EditTableModal';

import styles from './Empty.module.scss';
import Button from '~/ui/components/common/Button';
import { TableCustomizationType } from '~/services/api/enums';
import { cells } from '../../../constants';

const Empty = (): ReactElement => {
  const [isCustomizingDashboard, setIsCustomizingDashboard] = useState(false);

  return (
    <Paper elevation={24} square className={styles.container}>
      <Grid container spacing={3} direction="column" alignItems="center">
        <Grid item>
          <DashboardIcon color="disabled" className={styles.icon} />
        </Grid>
        <Grid item className={styles.title}>
          Your dashboard is currently empty!
        </Grid>
        <Grid item className={styles.description}>
          <Grid container direction="column" alignItems="center">
            <Grid item>
              You haven&apos;t added anything to your dashboard yet. Customize your dashboard by
              selecting your preferred charts and widgets.
            </Grid>
            <Grid item>To get started, click Customize Dashboard.</Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setIsCustomizingDashboard(true)}
          >
            Customize Dashboard
          </Button>
        </Grid>
      </Grid>
      {isCustomizingDashboard && (
        <EditTableModal
          typeId={TableCustomizationType.PatientOverview}
          title="Customize Dashboard"
          cells={cells}
          onClose={() => setIsCustomizingDashboard(false)}
        />
      )}
    </Paper>
  );
};

export default Empty;
