import { ICell } from '~/ui/components/reusable/EditTableModal/types';

export const cells: { [key: string]: ICell } = [
  {
    id: 'fullName',
    label: 'Name',
    useSort: true,
  },
  {
    id: 'subjectId',
    label: 'Patient ID',
    useSort: true,
  },
  {
    id: 'programName',
    label: 'Program Name',
    useSort: true,
  },
  {
    id: 'latestReadingMeasuredAt',
    label: 'Latest Reading',
    useSort: true,
    align: 'center',
  },
  {
    id: 'activityMinutesCount',
    label: 'Activity Minutes',
    useSort: true,
    align: 'center',
  },
  {
    id: 'uniqueDaysCount',
    label: 'Unique Days',
    useSort: true,
    align: 'center',
  },
  {
    id: 'totalReadingsCount',
    label: 'Total Readings',
    useSort: true,
    align: 'center',
  },
  {
    id: 'isHomeHealthCare',
    label: 'Home Health Care',
    useSort: true,
    align: 'center',
  },
  {
    id: 'status',
    label: 'Status',
    useSort: true,
  },
].reduce((result, x) => ({ ...result, [x.id]: x }), {});

export default {};
