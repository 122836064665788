import { ReactElement, useState } from 'react';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import Button from '~/ui/components/common/Button';

import { TemplateType } from '~/services/api/enums';
import UseTemplateModal from './UseTemplateModal';

interface IProps {
  typeId: TemplateType;
  onTemplateSelected: (content: string) => void;
}

const UseTemplateButton = ({ typeId, onTemplateSelected }: IProps): ReactElement => {
  const [isUseTemplateModalOpen, setIsUseTemplateModalOpen] = useState(false);

  const onUseTemplate = () => {
    setIsUseTemplateModalOpen(true);
  };

  const onCloseUseTemplateModal = () => {
    setIsUseTemplateModalOpen(false);
  };

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        fullWidth
        startIcon={<AssignmentOutlinedIcon />}
        onClick={onUseTemplate}
      >
        Use Template
      </Button>
      {isUseTemplateModalOpen && (
        <UseTemplateModal
          typeId={typeId}
          onTemplateSelected={onTemplateSelected}
          onClose={onCloseUseTemplateModal}
        />
      )}
    </>
  );
};

export default UseTemplateButton;
