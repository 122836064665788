import { ReactElement } from 'react';
import Tooltip from '@material-ui/core/Tooltip';

import AddNotificationRuleForm from '~/ui/components/reusable/ProgramForm/components/AddNotificationRuleForm';
import Button from '~/ui/components/common/Button';

import styles from './Form.module.scss';
import { INotificationRule } from '~/services/api/notificationRules/types';

interface IProps {
  disabledButton: boolean;
  notificationRules: INotificationRule[];
  addNotificationRule: (rule: INotificationRule) => void;
  showAddNotificationRule: boolean;
  setShowNotificationRule: (val: boolean) => void;
}

const Form = ({
  disabledButton,
  notificationRules,
  addNotificationRule,
  setShowNotificationRule,
  showAddNotificationRule,
}: IProps): ReactElement => (
  <div className={styles.container}>
    <div className={styles.headerContainer}>
      <h4>New Notification Rule</h4>
    </div>
    <div className={styles.formContainer}>
      {showAddNotificationRule ? (
        <AddNotificationRuleForm
          rules={notificationRules}
          onSubmit={addNotificationRule}
          onCancel={() => setShowNotificationRule(false)}
        />
      ) : (
        <Tooltip title={disabledButton ? 'The maximum number of notification rules used' : ''}>
          <span>
            <Button
              aria-label="delete"
              size="small"
              variant="contained"
              color="primary"
              onClick={() => setShowNotificationRule(true)}
              disabled={disabledButton}
            >
              + Add Rule
            </Button>
          </span>
        </Tooltip>
      )}
    </div>
  </div>
);

export default Form;
