import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CreateIcon from '@mui/icons-material/Create';

import styles from './InfoPanel.module.scss';
import { isoToFormat } from '~/utils/date';

interface IProps {
  startDate: string;
  endDate: string;
  label: string;
  total?: number;
  totalLabel?: string;
  backBtnUrl?: string;
  onEditTable?: () => void;
}

const InfoPanel = ({
  startDate,
  endDate,
  label,
  total,
  totalLabel,
  backBtnUrl = '',
  onEditTable = null,
}: IProps): ReactElement => {
  const from = isoToFormat(startDate, 'dd-MMM-y');
  const to = isoToFormat(endDate, 'dd-MMM-y');

  return (
    <div className={styles.container}>
      <div>{`${label} (From ${from} To ${to})`}</div>
      <div>
        {totalLabel && <span>{`Total ${totalLabel}: ${total}`}</span>}
        {backBtnUrl && (
          <Link to={backBtnUrl} className={styles.link}>
            <Button color="primary" variant="contained" startIcon={<ArrowBackIosIcon />}>
              Back
            </Button>
          </Link>
        )}
        {onEditTable && (
          <Button color="primary" onClick={onEditTable}>
            <CreateIcon /> &nbsp;Edit Table
          </Button>
        )}
      </div>
    </div>
  );
};

export default InfoPanel;
