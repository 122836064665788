import { action } from 'easy-peasy';
import { IActionUpdateCarePlanIntervention } from '../types';

const updateCarePlanIntervention: IActionUpdateCarePlanIntervention = action((state, payload) => {
  state.items = state.items
    .map(item =>
      item.id === payload.id
        ? {
            ...item,
            ...payload,
          }
        : item,
    )
    .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
});

export default updateCarePlanIntervention;
