import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkUpdateTemplate } from '../types';

// @ts-ignore
const onUpdateTemplate: IThunkUpdateTemplate = thunk(async ({ updateTemplate }, payload) => {
  const template = await api.templates.update(payload).then(res => res.data);
  updateTemplate(template);
});

export default onUpdateTemplate;
