import { ReactElement } from 'react';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';

import ModalTitle from './ModalTitle';

interface IProps {
  children: ReactElement;
  contentIdAttribute?: string;
  open: boolean;
  fullWidth?: boolean;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  title: string;
  containerClassName?: string;
  className?: string;
  onClose: () => void;
}

const Modal = ({
  children,
  contentIdAttribute = '',
  open,
  fullWidth,
  maxWidth = 'sm',
  title,
  containerClassName = '',
  className = '',
  onClose,
}: IProps): ReactElement => (
  <Dialog
    open={open}
    fullWidth={fullWidth}
    maxWidth={maxWidth}
    className={containerClassName}
    PaperProps={{ className }}
    onClose={onClose}
  >
    <ModalTitle onClose={onClose}>{title}</ModalTitle>
    <MuiDialogContent id={contentIdAttribute}>{children}</MuiDialogContent>
  </Dialog>
);

export default Modal;
