import { useEffect, useState, ReactElement } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Paper from '@material-ui/core/Paper';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import Grid from '@material-ui/core/Grid';

import api from '~/services/api';
import { useStoreActions } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';
import { isoToFormat } from '~/utils/date';
import { getInitialValues, validate } from './form';
import Button from '~/ui/components/common/Button';

import Divider from './Divider';
import GeneralInfoSection from './GeneralInfo';
import ContactInfoSection from './ContactInfo';
import HomeAddressSection from './HomeAddress';
import ShippingAddressSection from './ShippingAddress';
import EmergencyContactSection from './EmergencyContact';

import { IPatient } from '~/services/api/patients/types';
import styles from './PatientProfileForm.module.scss';
import { IIdName } from '~/services/api/types';
import { IPatientFormValues } from './types';
import { PatientStatus } from '~/services/api/enums';
import HomeHealthcareSection from './HomeHealthcareSection';
import CaretakerSection from './CaretakerSection';
import PhysiciansSection from './PhysiciansSection';

interface IProps {
  isProcessing: boolean;
  patient: IPatient;
  onUpdate: (payload: IPatient) => void;
  onRequestDischarge: () => void;
  onRequestActivate: () => void;
}

const PatientForm = ({
  isProcessing,
  patient,
  onUpdate,
  onRequestDischarge,
  onRequestActivate,
}: IProps): ReactElement => {
  const [managers, setManagers] = useState<IIdName[]>([]);

  const showError = useStoreActions(actions => actions.snackbar.showError);

  const formMethods = useForm<IPatientFormValues>({
    resolver: validate,
    defaultValues: getInitialValues(patient),
  });

  const { handleSubmit, reset } = formMethods;

  const isDischarged = patient.status === PatientStatus.Discharged;

  const managerOptions = managers.map(item => ({
    value: item.id,
    label: item.name,
  }));

  const onSubmit = (values: IPatientFormValues) => {
    const isHomeHealthCare = Boolean(Number(values.isHomeHealthCare));

    const payload = {
      ...patient,
      ...values,
      isHomeHealthCare,
      homePhone: values.homePhone === '' ? null : values.homePhone,
      cellPhone: values.cellPhone === '' ? null : values.cellPhone,
      workPhone: values.workPhone === '' ? null : values.workPhone,
      emergencyContactPhone:
        values.emergencyContactPhone === '' ? null : values.emergencyContactPhone,
      hospitalizationRiskScore: isHomeHealthCare
        ? values.hospitalizationRiskScore
        : patient.hospitalizationRiskScore,
      dateOfBirth: values.dateOfBirth ? isoToFormat(values.dateOfBirth, 'y-MM-dd') : null,
      shippingAddressSameAsHome: Boolean(Number(values.shippingAddressSameAsHome)),
      healthCareManagers: managers.filter(x => values.healthCareManagerIds.includes(x.id)),
      caretakers: values.caretakers.map(item => {
        const clonedItem = { ...item };
        if (patient.caretakers.some(x => x.phone === item.phone)) {
          clonedItem.id = patient.caretakers.find(x => x.phone === item.phone).id;
        }
        return clonedItem;
      }),
      homeHealthcarePeople: values.homeHealthcarePeople.map(item => {
        const clonedItem = { ...item };
        if (patient.caretakers.some(x => x.phone === item.phone)) {
          clonedItem.id = patient.caretakers.find(x => x.phone === item.phone).id;
        }
        return clonedItem;
      }),
    };

    onUpdate(payload);
  };

  const onMount = async () => {
    try {
      const selectors = await api.patients.getSelectorsById(patient.id).then(res => res.data);
      setManagers(selectors.users);
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  useEffect(() => {
    onMount();
  }, []);

  useEffect(() => {
    reset(getInitialValues(patient));
  }, [patient]);

  return (
    <Paper elevation={3} className={styles.patientProfile}>
      <div className={styles.header}>
        <div className={styles.title}>Patient profile</div>
        <Button
          variant="contained"
          color="primary"
          startIcon={<GroupAddIcon />}
          onClick={() => (isDischarged ? onRequestActivate() : onRequestDischarge())}
        >
          {isDischarged ? 'REQUEST ACTIVATE' : 'REQUEST DISCHARGE'}
        </Button>
      </div>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Divider title="General Info" showDividerLine={false} />
          <GeneralInfoSection managerOptions={managerOptions} />

          <Divider title="Contact Info" />
          <ContactInfoSection />

          <Divider title="Home Address" />
          <HomeAddressSection />

          <Divider title="Shipping Address" />
          <ShippingAddressSection />

          <Divider />
          <PhysiciansSection />

          <Divider />
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <HomeHealthcareSection />
            </Grid>

            <Grid item xs={6}>
              <CaretakerSection />
            </Grid>
          </Grid>

          <Divider title="Emergency Contact" />
          <EmergencyContactSection />

          <div className={styles.saveBtnWrapper}>
            <Button
              type="submit"
              size="large"
              variant="contained"
              color="primary"
              isLoading={isProcessing}
              disabled={isProcessing}
            >
              Save
            </Button>
          </div>
        </form>
      </FormProvider>
    </Paper>
  );
};

export default PatientForm;
