import axios from 'axios';
import { IResponse } from '~/services/api/types';
import { ICarePlan } from './types';

const apiUrl = 'care-plans';

export const getByPatientId = (patientId: number): IResponse<ICarePlan> =>
  axios({
    method: 'get',
    url: `${apiUrl}/by-patient-id/${patientId}`,
  });

export const reviewByPatientId = (patientId: number): IResponse<ICarePlan> =>
  axios({
    method: 'put',
    url: `${apiUrl}/review/by-patient-id/${patientId}`,
  });
