import { useState } from 'react';
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';

import { useStoreActions } from '~/store/hooks';
import { isoToFormat } from '~/utils/date';
import { extractErrorMessage } from '~/utils/error';
import { createValues } from './form';
import Button from '~/ui/components/common/Button';
import NoDataFound from '~/ui/components/reusable/NoDataFound';
import HospitalizationModal from '../popups/HospitalizationModal';
import Item from './Item';

import { ICarePlanHospitalization } from '~/services/api/carePlanHospitalizations/types';
import { IFormValues } from '../popups/HospitalizationModal/form/types';
import { IPagePermissions } from '../../types';
import styles from './Hospitalizations.module.scss';
import { SmokerOptions } from './constants';
import ConfirmModal from '~/ui/components/common/ConfirmModal';

interface IProps {
  patientId: number;
  items: ICarePlanHospitalization[];
  permissions: IPagePermissions;
  isPatientSmoker: boolean;
  onCarePlanChange: () => void;
}

const Hospitalizations = ({
  patientId,
  items,
  permissions,
  isPatientSmoker,
  onCarePlanChange,
}: IProps) => {
  const [showCreate, setShowCreate] = useState(false);
  const [showSmokerConfirm, setShowSmokerConfirm] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [isSmoker, setIsSmoker] = useState(isPatientSmoker);
  const [isSmokerUpdating, setIsSmokerUpdating] = useState(false);

  const { onCreateCarePlanHospitalization } = useStoreActions(
    actions => actions.carePlanHospitalizations,
  );
  const { onUpdateIsSmoker } = useStoreActions(actions => actions.patient);

  const { showError } = useStoreActions(actions => actions.snackbar);

  const handleCreateHospitalization = async (values: IFormValues) => {
    const payload = {
      ...values,
      date: isoToFormat(values.date, 'y-MM-dd'),
    } as ICarePlanHospitalization;

    try {
      setIsCreating(true);
      await onCreateCarePlanHospitalization({ patientId, data: payload });
      onCarePlanChange();
      setShowCreate(false);
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setIsCreating(false);
    }
  };

  const onSmokerChange = (value: boolean) => {
    setIsSmoker(value);
    setShowSmokerConfirm(true);
  };

  const handleChangeSmoker = async () => {
    try {
      setIsSmokerUpdating(true);
      await onUpdateIsSmoker({ patientId, data: { isSmoker } });
      setShowSmokerConfirm(false);
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setIsSmokerUpdating(false);
    }
  };

  const handleSmokerConfirmClose = () => {
    setIsSmoker(value => !value);
    setShowSmokerConfirm(false);
  };

  return (
    <div className={styles.container}>
      <div className={styles.headerWrapper}>
        <div className={styles.title}>Medical Notes / Hospitalizations</div>
        <div className={styles.actions}>
          <FormControl className={styles.smokerControl} disabled={!permissions.canManage}>
            <div className={styles.smokerLabel}>Smoker</div>
            <RadioGroup
              row
              value={isSmoker}
              onChange={(e, value) => onSmokerChange(value === 'true')}
            >
              {SmokerOptions.map(smokerOption => (
                <FormControlLabel
                  key={smokerOption.value.toString()}
                  value={smokerOption.value}
                  label={smokerOption.label}
                  control={<Radio color="primary" />}
                />
              ))}
            </RadioGroup>
          </FormControl>
          {permissions.canManage && (
            <Button variant="contained" color="primary" onClick={() => setShowCreate(true)}>
              + Add New
            </Button>
          )}
        </div>
      </div>
      <div className={styles.tableContainer}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell width="20%">Date</TableCell>
                <TableCell>Reason</TableCell>
                {permissions.canManage && <TableCell width={120} />}
              </TableRow>
            </TableHead>
            <TableBody>
              {items.length ? (
                items.map(item => (
                  <Item
                    key={item.id}
                    item={item}
                    permissions={permissions}
                    onCarePlanChange={onCarePlanChange}
                  />
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={3}>
                    <NoDataFound />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {showCreate && (
        <HospitalizationModal
          initialValues={createValues}
          isProcessing={isCreating}
          onSubmit={handleCreateHospitalization}
          onClose={() => setShowCreate(false)}
        />
      )}
      {showSmokerConfirm && (
        <ConfirmModal
          isLoading={isSmokerUpdating}
          title="Smoking Status"
          description="Are you sure you want to change the patient's smoking status?"
          onClose={handleSmokerConfirmClose}
          onConfirm={handleChangeSmoker}
        />
      )}
    </div>
  );
};

export default Hospitalizations;
