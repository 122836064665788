import { ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import { Grid } from '@material-ui/core';

import validate from './form/validate';
import Modal from '~/ui/components/common/Modal';
import Button from '~/ui/components/common/Button';
import DatePicker from '~/ui/components/inputs/DatePicker';
import Textarea from '~/ui/components/inputs/Textarea';

import { IFormValues } from './form/types';
import styles from './InterventionModal.module.scss';
import { TemplateType } from '~/services/api/enums';
import UseTemplateButton from '~/ui/components/reusable/UseTemplateButton';

interface IProps {
  mode?: 'create' | 'edit';
  initialValues: IFormValues;
  isProcessing: boolean;
  onClose: () => void;
  onSubmit: (values: IFormValues) => void;
}

const InterventionModal = ({
  mode = 'create',
  initialValues,
  isProcessing,
  onClose,
  onSubmit,
}: IProps): ReactElement => {
  const title = mode === 'create' ? 'Add Intervention' : 'Update Intervention';

  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: validate,
    defaultValues: initialValues,
  });

  const onTemplateSelected = (content: string) => {
    setValue('reason', content, { shouldValidate: true });
  };

  return (
    <Modal open maxWidth="sm" title={title} className={styles.modal} onClose={onClose}>
      <div className={styles.inner}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <DatePicker name="date" label="Date *" control={control} errors={errors} />
            </Grid>
            <Grid item xs={2} />
            <Grid item xs={4}>
              <UseTemplateButton
                typeId={TemplateType.Interventions}
                onTemplateSelected={onTemplateSelected}
              />
            </Grid>
            <Grid item xs={12}>
              <Textarea name="reason" label="Reason *" register={register} errors={errors} />
            </Grid>
          </Grid>
          <div className={styles.btnWrapper}>
            <div className={styles.widthSpacer} />
            <Button variant="outlined" color="default" disabled={isProcessing} onClick={onClose}>
              Cancel
            </Button>
            <div className={styles.widthSpacer} />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              isLoading={isProcessing}
              disabled={isProcessing}
            >
              {mode === 'create' ? 'Create' : 'Save'}
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default InterventionModal;
