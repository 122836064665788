import { ICell } from '~/ui/components/reusable/EditTableModal/types';

export const cells: { [key: string]: ICell } = [
  {
    id: 'generalGraphs',
    label: 'General Graphs',
  },
  {
    id: 'readingSummary',
    label: 'Reading Summary',
  },
  {
    id: 'notificationsSummary',
    label: 'Notifications Summary',
  },
  {
    id: 'averageReadingsMorningEvening',
    label: 'Average Readings (Morning/Evening)',
  },
  {
    id: 'readingCountOverPeriod',
    label: 'Reading Count Over Period',
  },
  {
    id: 'weeklyAnalysis',
    label: 'Weekly Analysis',
  },
  {
    id: 'weeklyGraphs',
    label: 'Weekly Graphs',
  },
  {
    id: 'readings',
    label: 'Readings',
  },
].reduce((result, x) => ({ ...result, [x.id]: x }), {});

export const smallCells = [
  'readingSummary',
  'notificationsSummary',
  'averageReadingsMorningEvening',
  'readingCountOverPeriod',
];

export default {};
