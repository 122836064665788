import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkGetTemplate } from '../types';

const onGetTemplate: IThunkGetTemplate = thunk(
  // @ts-ignore
  async ({ setTemplate }, id) => {
    const { data } = await api.templates.get(id);

    setTemplate(data);
  },
);

export default onGetTemplate;
