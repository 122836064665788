import { action } from 'easy-peasy';
import initStore from '../initStore';
import { IActionReset } from '../types';

const reset: IActionReset = action(state => {
  const { items, itemsByTypeId, pagination } = initStore;

  state.items = items;
  state.itemsByTypeId = itemsByTypeId;
  state.pagination = pagination;
});

export default reset;
