import { Button, Grid, IconButton } from '@material-ui/core';
import {
  Control,
  FieldErrors,
  useFieldArray,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form';
import DeleteIcon from '@mui/icons-material/Delete';
import api from '~/services/api';
import Checkbox from '~/ui/components/inputs/Checkbox';
import PhoneFlagInput from '~/ui/components/inputs/PhoneFlag/PhoneFlagInput';
import { IPatientFormValues } from '~/ui/pages/Patient/Dashboard/components/PersonalData/PatientProfile/types';
import styles from './PhoneInputsForm.module.scss';

interface IProps {
  control: Control<any>;
  name: 'caretakers' | 'homeHealthcarePeople';
  setValue: UseFormSetValue<IPatientFormValues>;
  register: UseFormRegister<IPatientFormValues>;
  errors: FieldErrors;
}

const PhoneInputsForm = ({ name, control, setValue, register, errors }: IProps) => {
  const { fields, append, remove } = useFieldArray<IPatientFormValues, typeof name, 'id'>({
    control,
    name,
  });

  const onPhoneInputBlur = async (
    value: string,
    element: 'caretakers' | 'homeHealthcarePeople',
    index: number,
  ) => {
    try {
      await api.mobileUsers.getByPhoneNumber(value).then(response => {
        if (response.data != null) {
          setValue(`${element}.${index}.hasAgreed`, response.data.hasAgreed);
        }
      });
    } catch (e) {
      setValue(`${element}.${index}.hasAgreed`, false);
    }
  };

  const registerWithoutRef = (controlName: 'phone' | 'hasAgreed', index: number) => {
    const { ref, ...rest } = register(`${name}.${index}.${controlName}` as const);

    return rest;
  };

  return (
    <>
      <Grid container spacing={3}>
        {fields.map((item, index) => (
          <Grid item xs={12} key={item.id}>
            <Grid container className={styles.container}>
              <Grid item xs={4}>
                <PhoneFlagInput
                  control={control}
                  name={`${name}${index}phoneNumber`}
                  errors={errors}
                  {...registerWithoutRef('phone', index)}
                  disabled={item.id >= 0}
                  onInputBlur={value => onPhoneInputBlur(value, name, index)}
                />
              </Grid>
              <Grid item xs={4} className={styles.controlButtons}>
                <IconButton className={styles.icon} title="Remove" onClick={() => remove(index)}>
                  <DeleteIcon />
                </IconButton>
                <Checkbox
                  name={`caretakers${index}hasAgreed`}
                  control={control}
                  errors={errors}
                  label="T&C/HIPAA"
                  {...registerWithoutRef('hasAgreed', index)}
                  disabled
                />
              </Grid>
            </Grid>
          </Grid>
        ))}
        <Grid item xs={4}>
          <span>
            <Button
              size="small"
              variant="text"
              color="primary"
              onClick={() =>
                append({
                  id: Date.now() * -1,
                  phone: '',
                  hasAgreed: false,
                })
              }
            >
              + Add New
            </Button>
          </span>
        </Grid>
      </Grid>
    </>
  );
};

export default PhoneInputsForm;
