import DashboardIcon from '@mui/icons-material/Dashboard';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import BuildIcon from '@mui/icons-material/Build';
import EnhancedEncryptionIcon from '@mui/icons-material/EnhancedEncryption';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import HistoryIcon from '@mui/icons-material/History';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotesIcon from '@mui/icons-material/Notes';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ListAltIcon from '@mui/icons-material/ListAlt';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';

import {
  DASHBOARD,
  PROGRAMS,
  INVENTORY,
  PATIENTS,
  USERS,
  REPORTS,
  ACTIVITY_LOGS,
  PATIENT_DASHBOARD,
  PATIENT_DEVICES,
  PATIENT_NOTIFICATION_RULES,
  PATIENT_NOTES,
  PATIENT_PLAN_OF_CARE,
  PATIENT_REPORTS,
  AUDIT_LOGS,
  TEMPLATES,
} from './paths';

import { UserRole, UserPermission } from '~/types';

interface IGeneralMenuItemsProps {
  userRole: UserRole;
  userPermissions: UserPermission[];
}

export const getGeneralMenuItems = ({ userRole, userPermissions }: IGeneralMenuItemsProps) => {
  const permissions = {
    programs: userRole !== UserRole.HealthManager,
    inventory:
      userRole !== UserRole.HealthManager || userPermissions.includes(UserPermission.ManageDevices),
    patients:
      userRole !== UserRole.HealthManager ||
      [UserPermission.ManagePatients, UserPermission.MonitorPatients].some(permission =>
        userPermissions.includes(permission),
      ),
    users:
      userRole !== UserRole.HealthManager ||
      [UserPermission.ManageUsers, UserPermission.ManageMobileUsers].some(permission =>
        userPermissions.includes(permission),
      ),
    reports:
      userRole !== UserRole.HealthManager || userPermissions.includes(UserPermission.ManageReports),
    activityLogs:
      userRole !== UserRole.HealthManager ||
      [UserPermission.ManagePatients, UserPermission.MonitorPatients].some(permission =>
        userPermissions.includes(permission),
      ),
    templates: [UserRole.SuperAdmin, UserRole.SystemAdmin].includes(userRole),
    auditLogs: userRole === UserRole.SystemAdmin,
  };

  return [
    {
      url: DASHBOARD,
      title: 'Dashboard',
      icon: DashboardIcon,
    },
    ...(permissions.programs
      ? [
          {
            url: PROGRAMS,
            title: 'Programs',
            icon: AccountTreeIcon,
          },
        ]
      : []),
    ...(permissions.inventory
      ? [
          {
            url: INVENTORY,
            title: 'Inventory',
            icon: BuildIcon,
          },
        ]
      : []),
    ...(permissions.patients
      ? [
          {
            url: PATIENTS,
            title: 'Patients',
            icon: EnhancedEncryptionIcon,
          },
        ]
      : []),
    ...(permissions.users
      ? [
          {
            url: USERS,
            title: 'Users',
            icon: PeopleAltIcon,
          },
        ]
      : []),
    ...(permissions.reports
      ? [
          {
            url: REPORTS,
            title: 'Reports',
            icon: InsertDriveFileIcon,
          },
        ]
      : []),
    ...(permissions.activityLogs
      ? [
          {
            url: ACTIVITY_LOGS,
            title: 'Activity Logs',
            icon: HistoryIcon,
          },
        ]
      : []),
    ...(permissions.templates
      ? [
          {
            url: TEMPLATES,
            title: 'Templates',
            icon: AssignmentOutlinedIcon,
          },
        ]
      : []),
    ...(permissions.auditLogs
      ? [
          {
            url: AUDIT_LOGS,
            title: 'Audit Logs',
            icon: ListAltIcon,
          },
        ]
      : []),
  ];
};

const getUrl = (url: string, patientId: string) => url.replace(':id', patientId);

export const getPatientMenuItems = (patientId: string) => [
  {
    url: getUrl(PATIENT_DASHBOARD, patientId),
    title: 'Dashboard',
    icon: DashboardIcon,
  },
  {
    url: getUrl(PATIENT_DEVICES, patientId),
    title: 'Devices',
    icon: DeviceHubIcon,
  },
  {
    url: getUrl(PATIENT_NOTIFICATION_RULES, patientId),
    title: 'Notification Rules',
    icon: NotificationsIcon,
  },
  {
    url: getUrl(PATIENT_NOTES, patientId),
    title: 'Notes',
    icon: NotesIcon,
  },
  {
    url: getUrl(PATIENT_PLAN_OF_CARE, patientId),
    title: 'Plan of Care',
    icon: AssignmentIcon,
  },
  {
    url: getUrl(PATIENT_REPORTS, patientId),
    title: 'Reports',
    icon: InsertDriveFileIcon,
  },
];
