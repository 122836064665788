import { ReactElement } from 'react';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { Tooltip } from '@material-ui/core';
import classNames from 'classnames';

import { getMeasurementData } from '../helper';
import { isoToFormat } from '~/utils/date';

import { Timezone } from '~/types';
import { INotificationRule } from '~/services/api/notificationRules/types';
import { IBloodPressureReading } from '~/services/api/readings/types';
import styles from './BloodPressure.module.scss';
import Icon from './BloodPressure.svg';
import { RuleType } from '~/services/api/enums';

interface IProps {
  reading: IBloodPressureReading;
  rules: INotificationRule[];
  timezone: Timezone;
  isHighlighted?: boolean;
}

const BloodPressure = ({ reading, rules, timezone, isHighlighted }: IProps): ReactElement => {
  const systolicData = getMeasurementData({
    typeId: RuleType.Systolic,
    value: reading.systolic,
    rules,
  });
  const diastolicData = getMeasurementData({
    typeId: RuleType.Diastolic,
    value: reading.diastolic,
    rules,
  });
  const heartRateData = getMeasurementData({
    typeId: RuleType.HeartRate,
    value: reading.pulse,
    rules,
  });

  const measurementDate = `${isoToFormat(reading.measuredAt, 'dd MMM y HH:mm')} ${timezone}`;

  const receivedBySystem = `Received by system: ${isoToFormat(
    reading.createdAt,
    'dd MMM y HH:mm',
  )} ${timezone}`;

  return (
    <div className={classNames(styles.container, { [styles.isHighlighted]: isHighlighted })}>
      <div className={styles.icon}>
        <Tooltip title="Blood Pressure">
          <img src={Icon} alt="" />
        </Tooltip>
      </div>
      <div className={styles.info}>
        <div className={styles.measurements}>
          <div className={systolicData.className}>
            <span className={styles.value}>{systolicData.value}</span>
          </div>
          <div className={diastolicData.className}>
            <span className={styles.value}>{diastolicData.value}</span>
          </div>
          <div className={heartRateData.className}>
            <span className={styles.value}>{heartRateData.value}</span>
          </div>
        </div>
        <div className={styles.date} title={receivedBySystem}>
          {measurementDate}
        </div>
      </div>
      <div className={styles.statusIcon}>
        {reading.irregularHeartbeat && (
          <Tooltip title="Irregular heart beat">
            <FavoriteIcon className={styles.heartBeatIcon} />
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default BloodPressure;
