import { ReactElement } from 'react';
import { Grid } from '@material-ui/core';
import { HowToReg, PersonRemove } from '@mui/icons-material';
import { format } from 'date-fns';
import { IPatientOverview } from '~/services/api/patients/types';
import SubHeaderItem from './SubHeaderItem';
import HeartRateIcon from './HeartRate.svg';

import styles from './SubHeader.module.scss';

interface IProps {
  patientOverview: IPatientOverview;
}

const SubHeader = ({ patientOverview }: IProps): ReactElement =>
  patientOverview ? (
    <Grid container spacing={1} alignItems="center" justifyContent="space-between">
      <Grid item>
        <SubHeaderItem
          icon={<HowToReg className={styles.green} />}
          text={`Enrolled: ${format(new Date(patientOverview.createdAt), 'dd MMM y')}`}
        />
      </Grid>
      {patientOverview.dischargedAt !== null && (
        <Grid item>
          <SubHeaderItem
            icon={<PersonRemove className={styles.red} />}
            text={`Discharged: ${format(new Date(patientOverview.dischargedAt), 'dd MMM y')}`}
          />
        </Grid>
      )}
      <Grid item xs />
      {patientOverview.irregularHeartbeatsCount != null && (
        <Grid item>
          <SubHeaderItem
            icon={<img src={HeartRateIcon} alt="" />}
            text={`Irregular Heartbeat: ${patientOverview.irregularHeartbeatsCount} / ${patientOverview.readings.length}`}
          />
        </Grid>
      )}
    </Grid>
  ) : (
    <></>
  );

export default SubHeader;
