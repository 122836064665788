import { useState, ReactElement, useEffect, useMemo } from 'react';
import { Grid } from '@material-ui/core';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { useStoreActions, useStoreState } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';
import Modal from '~/ui/components/common/Modal';
import { TemplateType } from '~/services/api/enums';
import Button from '~/ui/components/common/Button';
import { IFormValues } from './types';
import initialValues from './form/initialValues';
import Loader from '~/ui/components/common/Loader';
import Input from '~/ui/components/inputs/Input';
import { ITemplateSelector } from '~/services/api/templates/types';
import styles from './UseTemplateModal.module.scss';
import NoDataFound from '../../NoDataFound';

interface IProps {
  typeId: TemplateType;
  onTemplateSelected: (content: string) => void;
  onClose: () => void;
}

const UseTemplateModal = ({ typeId, onTemplateSelected, onClose }: IProps): ReactElement => {
  const [isLoading, setIsLoading] = useState(true);
  const [selectedTemplate, setSelectedTemplate] = useState<ITemplateSelector>(null);

  const initialTemplates = useStoreState(state => state.templates.itemsByTypeId[typeId]);

  const { showError } = useStoreActions(actions => actions.snackbar);
  const { onGetTemplatesByTypeId } = useStoreActions(actions => actions.templates);

  const {
    register,
    watch,
    formState: { errors },
  } = useForm<IFormValues>({
    defaultValues: initialValues,
  });

  const name = watch('name').toLowerCase();

  const templates = useMemo(
    () => initialTemplates.filter(x => x.name.toLowerCase().includes(name)),
    [initialTemplates, name],
  );

  const onMount = async () => {
    try {
      await onGetTemplatesByTypeId(typeId);

      setIsLoading(false);
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  useEffect(() => {
    onMount();
  }, []);

  const onTemplateClick = (template: ITemplateSelector) => {
    setSelectedTemplate(template);
  };

  const onApply = () => {
    onTemplateSelected(selectedTemplate.content);
    onClose();
  };

  return (
    <Modal open fullWidth title="Templates" maxWidth="sm" onClose={onClose}>
      <>
        {isLoading ? (
          <Loader />
        ) : (
          <form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Input name="name" register={register} errors={errors} label="Search" />
              </Grid>
              <Grid item xs={12}>
                <Grid
                  container
                  className={styles.templatesContainer}
                  justifyContent="center"
                  alignContent="flex-start"
                >
                  {templates.length > 0 ? (
                    templates.map(template => (
                      <Grid
                        item
                        key={template.id}
                        xs={12}
                        onClick={() => onTemplateClick(template)}
                      >
                        <Grid
                          container
                          className={classNames(styles.templateContainer, {
                            [styles.isSelected]: selectedTemplate?.id === template.id,
                          })}
                          alignItems="center"
                          justifyContent="space-between"
                          wrap="nowrap"
                        >
                          <Grid item className={styles.name}>
                            {template.name}
                          </Grid>
                        </Grid>
                      </Grid>
                    ))
                  ) : (
                    <NoDataFound />
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2} justifyContent="flex-end">
                  <Grid item>
                    <Button size="large" variant="outlined" color="primary" onClick={onClose}>
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      size="large"
                      variant="contained"
                      color="primary"
                      disabled={selectedTemplate === null}
                      onClick={onApply}
                    >
                      Apply
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        )}
      </>
    </Modal>
  );
};

export default UseTemplateModal;
