import { Grid, IconButton } from '@material-ui/core';
import { FieldValues, useFieldArray, useFormContext } from 'react-hook-form';
import DeleteIcon from '@mui/icons-material/Delete';
import { AddCircleOutline } from '@mui/icons-material';
import { IMobileUserInfo } from '~/services/api/mobileUsers/types';
import Button from '~/ui/components/common/Button';
import PhoneFlagInput from '~/ui/components/inputs/PhoneFlag/PhoneFlagInput';
import styles from './CaretakerSection.module.scss';
import Checkbox from '~/ui/components/inputs/Checkbox';
import api from '~/services/api';

interface IFormFields extends FieldValues {
  caretakers: IMobileUserInfo[];
}

const CaretakerSection = () => {
  const {
    register,
    control,
    formState: { errors },
    setValue,
  } = useFormContext<IFormFields>();

  const { fields, append, remove } = useFieldArray<IFormFields, 'caretakers', 'id'>({
    control,
    name: 'caretakers',
  });

  const onPhoneInputBlur = async (value: string, index: number) => {
    try {
      await api.mobileUsers.getByPhoneNumber(value).then(response => {
        if (response.data != null) {
          setValue(`caretakers.${index}.hasAgreed`, response.data.hasAgreed);
          setValue(`caretakers.${index}.mobileUserRoleId`, response.data.mobileUserRoleId);
        }
      });
    } catch (e) {
      setValue(`caretakers.${index}.hasAgreed`, false);
    }
  };

  const registerWithoutRef = (controlName: 'phone' | 'hasAgreed', index: number) => {
    const { ref, ...rest } = register(`caretakers.${index}.${controlName}` as const);

    return rest;
  };

  const onAdd = () => {
    append({
      id: Date.now() * -1,
      phone: '',
      hasAgreed: false,
    });
  };

  return (
    <>
      <Grid container className={styles.titleContainer} spacing={4} alignItems="center">
        <Grid item className={styles.title}>
          Caretaker
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        {fields.map((item, index) => (
          <Grid item xs={12} key={item.id}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <PhoneFlagInput
                  control={control}
                  name={`caretakers${index}phoneNumber`}
                  errors={errors}
                  {...registerWithoutRef('phone', index)}
                  disabled={item.id >= 0}
                  onInputBlur={value => onPhoneInputBlur(value, index)}
                />
              </Grid>
              <Grid item xs={4} className={styles.controlButtons}>
                <IconButton className={styles.icon} title="Remove" onClick={() => remove(index)}>
                  <DeleteIcon />
                </IconButton>
                <Checkbox
                  name={`caretakers${index}hasAgreed`}
                  control={control}
                  errors={errors}
                  label="T&C/HIPAA"
                  {...registerWithoutRef('hasAgreed', index)}
                  disabled
                />
              </Grid>
            </Grid>
          </Grid>
        ))}
        <Grid item>
          <span>
            <Button
              variant="outlined"
              color="primary"
              fullWidth
              startIcon={<AddCircleOutline />}
              onClick={onAdd}
            >
              Add More
            </Button>
          </span>
        </Grid>
      </Grid>
    </>
  );
};

export default CaretakerSection;
