import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkCreateTemplate } from '../types';

// @ts-ignore
const onCreateTemplate: IThunkCreateTemplate = thunk(async ({ addTemplate }, data) => {
  const template = await api.templates.create(data).then(res => res.data);
  addTemplate(template);
});

export default onCreateTemplate;
