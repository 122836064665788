import { IFormValues } from '../../popups/MedicationModal/form/types';

const createValues: IFormValues = {
  name: '',
  reason: '',
  startDate: null,
  endDate: null,
};

export default createValues;
