import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkDeleteTemplate } from '../types';

// @ts-ignore
const onDeleteTemplate: IThunkDeleteTemplate = thunk(async ({ deleteTemplate }, id) => {
  await api.templates.remove(id);
  deleteTemplate(id);
});

export default onDeleteTemplate;
