import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkRemoveCarePlanGoal } from '../types';

const onRemoveCarePlanGoal: IThunkRemoveCarePlanGoal = thunk(
  // @ts-ignore
  async ({ removeCarePlanGoal }, id) => {
    await api.carePlanGoals.remove(id);
    removeCarePlanGoal(id);
  },
);

export default onRemoveCarePlanGoal;
