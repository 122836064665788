import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkDeleteReadings } from '../types';

// @ts-ignore
const onDeleteReadings: IThunkDeleteReadings = thunk(async ({ deleteReadings }, ids) => {
  await api.readings.remove(ids).then(res => res.data);
  deleteReadings(ids);
});

export default onDeleteReadings;
