import initStore from './initStore';
import actions from './actions';
import thunks from './thunks';

import { ITemplatesModel } from './types';

const templates: ITemplatesModel = {
  ...initStore,
  ...actions,
  ...thunks,
};

export default templates;
