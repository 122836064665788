import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkGetCarePlanGoals } from '../types';

const onGetCarePlanGoals: IThunkGetCarePlanGoals = thunk(
  // @ts-ignore
  async ({ setCarePlanGoals }, patientId) => {
    const { data } = await api.carePlanGoals.getList(patientId);
    setCarePlanGoals(data);
  },
);

export default onGetCarePlanGoals;
