import { TableCustomizationType } from '~/services/api/enums';
import { ITableCustomizationsStore } from './types';

const initStore: ITableCustomizationsStore = {
  items: {
    [TableCustomizationType.Patients]: [],
    [TableCustomizationType.PatientOverview]: [],
  },
};

export default initStore;
