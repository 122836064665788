import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkGetCarePlanInterventions } from '../types';

const onGetCarePlanInterventions: IThunkGetCarePlanInterventions = thunk(
  // @ts-ignore
  async ({ setCarePlanInterventions }, patientId) => {
    const { data } = await api.carePlanInterventions.getList(patientId);
    setCarePlanInterventions(data);
  },
);

export default onGetCarePlanInterventions;
